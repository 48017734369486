// TDTA 查询接口
export interface TDTAManagementQueryParams {
    taName?: string | null;
    programCode?: string | null;
    year?: string | null;
    quarter?: string | null;
    month?: string | null;
    current?: number;
    size?: number;
}

// TAManagement 表格数据接口
export interface TDTAManagementTableData {
    id?: number;
    taname: string;
    programcode: string;
    year: string;
    quarter: string;
    month: string;
    vehicle: string;
    dealercount: number;
    totalcount: number;
    updateuser: string;
    updatetime: string;
}

export const tdtaColumns = [
    { title: 'Name', dataIndex: 'taName' },
    {
        title: 'Program Code',
        dataIndex: 'programCode',
        width: 180,
    },
    {
        title: 'Year',
        dataIndex: 'year',
        width: 80,
    },
    {
        title: 'Quarter',
        dataIndex: 'quarter',
        width: 90,
    },
    {
        title: 'Month',
        dataIndex: 'month',
        width: 80,
    },
    {
        title: 'Type',
        dataIndex: 'type',
        width: 80,
    },
    {
        title: 'Vehicle',
        dataIndex: 'vehicle',
        width: 320,
    },
    {
        title: 'Dealer Count',
        dataIndex: 'dealerCount',
        width: 130,
    },
    {
        title: 'Total Count',
        dataIndex: 'totalCount',
        width: 130,
    },
    {
        title: 'Upload User',
        dataIndex: 'updateUser',
        width: 180,
    },
    {
        title: 'Upload Time',
        dataIndex: 'updateTime',
        width: 180,
    },
];
