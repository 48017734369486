
import { ref, defineComponent, reactive, toRefs, watch } from 'vue';
import { message } from 'ant-design-vue';
import UploadDetail from './UploadDetail/index.vue';
import useManagement from '@/hooks/checking/useTDTAManagement';
import { useRoute } from 'vue-router';
import downloadFile from '@/utils/claim/downloadFile';
import fixTable from '@/hooks/checking/useFixTable';
//TODO TDTA的 Vehicle 中的级联的值 点击后重置其他的；brand最高级 清空其他
export default defineComponent({
    components: {
        UploadDetail,
    },
    setup() {
        const {
            fakeDataList,
            name,
            programCode,
            year,
            yearList,
            quarter,
            quarterList,
            month,
            monthList,
            searchParams,
            columns,
            getTableData,
            tableData,
            paginationObj,
            pageChange,
            sizeChange,
        } = useManagement();

        watch(quarter, (newVal, oldVal) => {
            if (newVal !== oldVal) {
                month.value = [];
            }
        });

        //* 限定表格
        const height: number = 100 + 50 + 210 + 10 + 15 + 32 + 10 + 48 + 10;
        const tableHeight = fixTable(height);

        const state = reactive<any>({
            selectedRowKeys: [], // Check here to configure the default column
            selectedRows: [],
        });
        const onSelectChange = (selectedRowKeys: [], selectedRows: []) => {
            state.selectedRowKeys = selectedRowKeys;
            state.selectedRows = selectedRows;
        };

        //? 上传功能逻辑
        const isUploadVisible = ref<boolean>(false);
        const openUploadDrawer = () => {
            isUploadVisible.value = true;
        };
        const closeUpload = (param: { isUploadVisible: boolean }) => {
            isUploadVisible.value = param.isUploadVisible;
            getTableData();
        };

        //? 导出功能
        const exportData = (): void => {
            const selectedRow = state.selectedRows[0];
            if (selectedRow === undefined) {
                message.error('Please select one and click again!');
                return;
            }
            const params = {
                url: '/claimapi/tdta/exportTDTA',
                method: 'get',
                params: {
                    taName: selectedRow.taName,
                    programCode: selectedRow.programCode,
                },
            };
            downloadFile(params, 'application/vnd-excel; char-set=UTF-8');
        };

        const reset = () => {
            name.value = '';
            programCode.value = '';
            year.value = null;
            quarter.value = null;
            month.value = [];
        };

        return {
            fakeDataList,
            //
            name,
            programCode,
            year,
            yearList,
            quarter,
            quarterList,
            month,
            monthList,
            //
            tableHeight,
            searchParams,
            columns,
            reset,
            getTableData,
            tableData,
            onSelectChange,
            ...toRefs(state),
            //
            isUploadVisible,
            paginationObj,
            pageChange,
            sizeChange,
            openUploadDrawer,
            closeUpload,
            exportData,
        };
    },
});
