
//TODO vehicle 级联选择清空后面的数据内容
import axios from 'axios';
import {
    computed,
    defineComponent,
    reactive,
    ref,
    watch,
    onMounted,
} from 'vue';
import {
    ExclamationCircleOutlined,
    CheckCircleOutlined,
} from '@ant-design/icons-vue';
// import { getUploadRoundList } from '@/API/claim/claimWS';
// import { PayoutRoundListData } from '@/views/Claim/WS/types';
import { FileItem } from '@/API/types';
import downloadFile from '@/utils/claim/downloadFile';
import downloadFileUrl from '@/utils/claim/downloadFileUrl';
import { message } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import {
    getBrand,
    getUser,
    searchVehicle,
    findProgramCodeList,
    getProgramTANameList,
} from '@/API/checking/checkingManagement';

interface BrandType {
    id: number;
    brandNameCn: string;
    brandNameEn: string;
}

export default defineComponent({
    components: {
        ExclamationCircleOutlined,
        CheckCircleOutlined,
    },
    emits: ['close-upload-visible', 'change-table'],
    props: {
        isUploadVisible: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    setup(props, context) {
        //? 必选校验
        const isEmptyProgramCode = ref<boolean>(false);
        const isEmptyTAName = ref<boolean>(false);
        const isEmptyYear = ref<boolean>(false);
        const isEmptyBrand = ref<boolean>(false);

        const isTypeEqualRT = ref<boolean>(false);
        const isReUpload = ref<boolean>(false);

        //
        const bu = ref<string | null>(null);
        const buName = ref<string>('');
        const buList = ref<any[]>([]);

        const type = ref<string | null>(null);
        const typeList = ref<string[]>(['WS', 'RT', 'ATD']);
        type.value = typeList.value[1];

        const programCode = ref<string | null>(null);
        const programCodeList = ref<string[]>();

        const taName = ref<string | null>(null);
        const taNameList = ref([]);

        //* select period
        const year = ref<string | null>(null);
        const yearList = ref<number[]>([]);
        const getYearList = (): number[] => {
            const currentDate: Date = new Date();
            const currentYear = currentDate.getFullYear();
            const yearList: number[] = [currentYear, currentYear + 1];
            return yearList;
        };
        yearList.value = getYearList();

        const quarter = ref<string | null>(null);
        const quarterList = ref<string[]>(['Q1', 'Q2', 'Q3', 'Q4']);

        const month = ref<string[]>([]);
        const monthList = reactive({
            Q1: ['Jan', 'Feb', 'Mar'],
            Q2: ['Apr', 'May', 'Jun'],
            Q3: ['Jul', 'Aug', 'Sep'],
            Q4: ['Oct', 'Nov', 'Dec'],
        });

        //* select vehicle
        const brand = ref<string | null>(null);
        const brandList = ref<BrandType[]>([]);
        const findBrandByBu = () => {
            const params = { buIds: [bu.value] };
            getBrand({ params }).then((res) => {
                const selectedIdList: number[] = [];
                const totalBrandList: {
                    id: number;
                    brandNameCn: string;
                    brandNameEn: string;
                }[] = [];
                res.forEach((bu: any) => {
                    bu.brandList.forEach((brand: BrandType) => {
                        if (!selectedIdList.includes(brand.id)) {
                            selectedIdList.push(brand.id);
                            totalBrandList.push(brand);
                        }
                    });
                });
                brandList.value = totalBrandList;
            });
        };

        const make = ref<string[]>([]);
        const makeList = ref<string[]>([]);

        const typeClass = ref<string[]>([]);
        const typeClassList = ref<string[]>([]);

        const modelName = ref<string[]>([]);
        const modelNameList = ref<string[]>([]);

        const nst = ref<string[]>([]);
        const nstList = ref<string[]>([]);

        const powerTrain = ref<string[]>([]);
        const powerTrainList = ref<string[]>([]);

        const getVehicleItems = () => {
            const params = {
                brand: [brand.value],
                make: make.value,
                modelName: modelName.value,
                nst: nst.value,
                typeClass: typeClass.value,
                powerTrain: powerTrain.value,
            };
            searchVehicle(params).then((res: any) => {
                makeList.value = res.make;
                modelNameList.value = res.modelName;
                nstList.value = res.nst;
                powerTrainList.value = res.powerTrain;
                typeClassList.value = res.typeClass;
                powerTrainList.value = res.powerTrain;
            });
        };

        //? 数据初始化阶段
        onMounted(() => {
            getUser()
                .then((res) => {
                    buList.value = res.buList.filter((ele: any) => {
                        return ele.parentId === null;
                    });
                    bu.value = buList.value[0].id;
                    buName.value = buList.value[0].nameEn;
                })
                .then(() => {
                    return findBrandByBu();
                });
        });

        const selectBu = (name: string) => {
            for (let i = 0; i < buList.value.length; i++) {
                if (buList.value[i].id === name) {
                    buName.value = buList.value[i].nameEn;
                }
            }

            brand.value = null;
            // isEmptyBrand.value = true;
            make.value = [];
            modelName.value = [];
            nst.value = [];
            powerTrain.value = [];
            typeClass.value = [];
            programCode.value = '';
            programCodeList.value = [];
            findBrandByBu();
        };

        const selectBrand = () => {
            // isEmptyBrand.value = brand.value === null ? true : false;
            make.value = [];
            modelName.value = [];
            nst.value = [];
            powerTrain.value = [];
            typeClass.value = [];
            getVehicleItems();
        };

        const coverFlag = ref<string>('N');

        let timeout: any;
        const handleSearch = (val: string) => {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            const params = {
                code: val,
            };
            function getData() {
                if (!bu.value) {
                  programCodeList.value = [];
                  return;
                }
                findProgramCodeList(bu.value, val).then((res: any) => {
                    programCodeList.value = res;
                });
            }
            timeout = setTimeout(getData, 800);
        };
        const handleChange = (val: string) => {
            programCode.value = val;
            getProgramTANameList({ code: val }).then((res: any) => {
                taNameList.value = res;
            });
        };

        watch(
            type,
            (newVal) => {
                if (newVal === 'RT') {
                    isTypeEqualRT.value = true;
                } else {
                    isTypeEqualRT.value = false;
                }
            },
            { immediate: true }
        );

        const getMonthStr = (seperator: string): string => {
            const chosenMonthes = monthList[quarter.value as string];
            return month.value
                .sort()
                .reduce((arr: any, m: string) => {
                    return [...arr, chosenMonthes[m]];
                }, [])
                .join(seperator);
        };

        const rateStr = computed(() => {
            if (year.value === null || brand.value === null) return;
            const period = `${year.value} ${quarter.value || ''} ${getMonthStr(
                '&'
            )}`;
            const vehicle = `${brand.value} ${make.value.join(
                '&'
            )} ${typeClass.value.join('&')} ${modelName.value.join(
                '&'
            )} ${nst.value.join('&')} ${powerTrain.value.join('&')}`;
            return `${period}, ${vehicle}`;
        });
        //!
        // const isShowErrorTips = ref<boolean>(false);
        const isBeforeUpload = ref<boolean>(true);
        // 进度条
        const isShowUploadBtn = ref<boolean>(true);
        const curUploadFile = ref<object>({});
        const curPercent = ref<number>(0);
        const uploadFileSize = ref<string>('');
        const completeTips = ref<boolean>(false);
        // 上传功能出错后下载模态窗口
        const loading = ref<boolean>(false);
        const downloadModalVisible = ref<boolean>(false);
        const downloadTipsText = ref<string>('');
        const roundVal = ref();
        // 导入控制按钮
        const isImport = ref<boolean>(false);
        // 文件上传
        const isClickAbled = ref<boolean>(true);
        const uploadComplete = ref<boolean>(true);
        const formData = new FormData();
        const emptyVisible = ref<boolean>(false);
        const emptyTipsText = ref<string>('');
        // 获取标题内容
        const title = ref<string>('Upload TDTA');
        // 设置抽屉显示状态
        const modal = (props as any).isUploadVisible;
        const close = () => {
            context.emit('close-upload-visible', { isUploadVisible: false });
        };

        // 上传前
        const beforeUpload = (file: FileItem) => {
            const isXlsx =
                file.type ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            if (!isXlsx) {
                message.error('Only xlsx format files can be uploaded!');
            }
            const isLt5M = file.size / 1024 / 1024 < 5;
            if (!isLt5M) {
                message.error('The file size should not be larger than 5MB!');
            }
            return isXlsx && isLt5M;
        };
        const uploadRequest = (source: any) => {
            if (formData.has('file')) {
                formData.delete('file');
            }
            formData.append('file', source.file);
            curUploadFile.value = source.file;
            uploadFileSize.value =
                source.file.size / 1024 > 1024
                    ? `${(source.file.size / 1024 / 1024).toFixed(1)} MB`
                    : `${Math.floor(source.file.size / 1024)} KB`;
            isBeforeUpload.value = false;
        };

        const queryCheck = (): boolean => {
            if (year.value === undefined) {
                isEmptyYear.value = true;
            } else if (year.value === null) {
                isEmptyYear.value = true;
            } else {
                isEmptyYear.value = false;
            }
            if (brand.value === undefined || brand.value === null) {
                isEmptyBrand.value = true;
            } else if (brand.value?.trim() === '') {
                isEmptyBrand.value = true;
            } else {
                isEmptyBrand.value = false;
            }
            if (programCode.value === null) {
                isEmptyProgramCode.value = true;
            } else {
                isEmptyProgramCode.value = false;
            }
            if (taName.value === null) {
                isEmptyTAName.value = true;
            } else {
                isEmptyTAName.value = false;
            }
            if (
                isEmptyYear.value ||
                isEmptyBrand.value ||
                isEmptyProgramCode.value ||
                isEmptyTAName.value
            ) {
                return true;
            } else {
                return false;
            }
        };

        // 上传文件
        const uploadFile = () => {
            if (queryCheck()) return;
            isClickAbled.value = false;

            let url = '';
            const params = {
                businessUnit: bu.value,
                businessUnitName: buName.value,
                type: type.value,
                year: year.value,
                quarter: quarter.value,
                month: getMonthStr(','),
                brand: brand.value,
                make: make.value.join(','),
                typeClass: typeClass.value.join(','),
                modelName: modelName.value.join(','),
                nst: nst.value.join(','),
                powerTrain: powerTrain.value.join(','),
                programCode: programCode.value,
                taName: taName.value,
                coverFlag: coverFlag.value,
                file: formData,
            };
            url = '/claimapi/tdta/uploadSave';
            axios({
                method: 'post',
                data: formData,
                url,
                params: params,
            }).then((res) => {
                isShowUploadBtn.value = false;
                curPercent.value = 100;
                completeTips.value = true;
                isClickAbled.value = true;
                if (
                    res.data.code !== '0' &&
                    res.data.code !== '50056' &&
                    res.data.code !== '50201'
                ) {
                    emptyVisible.value = true;
                    emptyTipsText.value = res.data.message;
                } else if (res.data.code === '50056') {
                    // 显示模态窗口
                    downloadModalVisible.value = true;
                    downloadTipsText.value = res.data.message;
                    // 存data里的信息
                    if (res.data.data !== '')
                        window.sessionStorage.setItem(
                            'errorFileName',
                            res.data.data
                        );
                } else if (res.data.code === '50201') {
                    // 显示模态窗口
                    isReUpload.value = true;
                    downloadModalVisible.value = true;
                    downloadTipsText.value = res.data.message;
                } else {
                    message.success(res.data.message);
                    isImport.value = true;
                }
            });
            coverFlag.value = 'N';
        };
        // 上传功能出错后下载模态窗口
        const handleDownload = () => {
            loading.value = true;
            // 调下载错误文件接口
            const path = window.sessionStorage.getItem('errorFileName')
                ? window.sessionStorage.getItem('errorFileName')
                : null;
            if (!path) return;
            const downloadParams = {
                path: window.sessionStorage.getItem('errorFileName'),
            };
            const params = {
                url: `/claimapi/common/download/errorFile`,
                method: 'get',
                params: downloadParams,
                fileName: `${+new Date()}.xlsx`,
            };
            downloadFileUrl(
                params,
                'application/vnd-excel; char-set=UTF-8'
            ).then((res) => {
                loading.value = false;
                downloadModalVisible.value = false;
                emptyVisible.value = false;
            });
        };
        const handleCancel = () => {
            isReUpload.value = false;
            downloadModalVisible.value = false;
        };
        const showUploadHandle = () => {
            isBeforeUpload.value = true;
            isShowUploadBtn.value = true;
            curPercent.value = 0;
            completeTips.value = false;
            window.sessionStorage.removeItem('errorFileName');
            isImport.value = false;
        };

        // 下载模板接口调用
        const downloadTemHandle = () => {
            if (queryCheck()) return;
            const params = {
                url: `/claimapi/tdta/templateDownload?TAName=${taName.value}&programCode=${programCode.value}`,
                method: 'get',
            };
            downloadFile(params, 'application/vnd-excel; char-set=UTF-8');
        };

        const handleReupload = () => {
            isReUpload.value = false;
            coverFlag.value = 'Y';
            uploadFile();
            downloadModalVisible.value = false;
        };

        return {
            isTypeEqualRT,
            isReUpload,
            rateStr,
            bu,
            buList,
            programCode,
            programCodeList,
            taName,
            taNameList,
            type,
            typeList,
            year,
            yearList,
            quarter,
            quarterList,
            month,
            monthList,
            brand,
            brandList,
            make,
            makeList,
            typeClass,
            typeClassList,
            modelName,
            modelNameList,
            nst,
            nstList,
            powerTrain,
            powerTrainList,
            selectBrand,
            getVehicleItems,
            selectBu,
            isEmptyProgramCode,
            isEmptyTAName,
            isEmptyYear,
            isEmptyBrand,
            //
            loading,
            // isShowErrorTips,
            downloadModalVisible,
            downloadTipsText,
            handleReupload,
            handleDownload,
            handleCancel,
            roundVal,
            title,
            modal,
            close,
            uploadFile,
            beforeUpload,
            downloadTemHandle,
            uploadComplete,
            isBeforeUpload,
            isShowUploadBtn,
            uploadRequest,
            curPercent,
            curUploadFile,
            uploadFileSize,
            isImport,
            showUploadHandle,
            completeTips,
            isClickAbled,
            emptyVisible,
            emptyTipsText,
            handleChange,
            handleSearch,
        };
    },
});
