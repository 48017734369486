import usePage from '@/hooks/claim/usePage';
import range from '@/utils/checking/rangeArray';
import { ref, reactive, watch, onMounted } from 'vue';
import {
    TDTAManagementQueryParams,
    TDTAManagementTableData,
    tdtaColumns,
} from '@/views/Checking/TDTAManagement/type';
import { getTDTAManagementData } from '@/API/checking/checkingManagement';
import { useRoute } from 'vue-router';

const STARTYEAR = 2020;
const myDate: Date = new Date();
const endYear: number = myDate.getFullYear() + 2;

const genFakeData = (num: number): TDTAManagementTableData[] => {
    const fakeDataList: TDTAManagementTableData[] = [];
    for (let i = 0; i < num; i++) {
        const fakeDataObj: TDTAManagementTableData = {
            id: i,
            taname: `name ${i}`,
            programcode: '123',
            year: '2021',
            quarter: 'Q1',
            month: 'Jan',
            vehicle: '151',
            dealercount: 230,
            totalcount: 202,
            updateuser: '1231',
            updatetime: '123',
        };
        fakeDataList.push(fakeDataObj);
    }
    return fakeDataList;
};

const useManagement = () => {
    const route = useRoute();

    const name = ref<string | null>(null);
    const programCode = ref<string | null>(null);
    const year = ref<string | null>(null);
    const yearList = ref<number[]>(range(STARTYEAR, endYear, 1));
    const quarter = ref<string | null>(null);
    const quarterList = ref<string[]>(['Q1', 'Q2', 'Q3', 'Q4']);
    const month = ref<string[]>([]);
    const monthList = reactive({
        Q1: ['Jan', 'Feb', 'Mar'],
        Q2: ['Apr', 'May', 'Jun'],
        Q3: ['Jul', 'Aug', 'Sep'],
        Q4: ['Oct', 'Nov', 'Dec'],
    });

    const { paginationObj, pageChange, sizeChange } = usePage();

    const columns = ref<{ title: string; dataIndex: string; width?: number }[]>(
        []
    );

    //* 表头
    const TDTAColumns = reactive(tdtaColumns);
    columns.value = TDTAColumns;

    const getMonthStr = (): string => {
        const chosenMonthes = monthList[quarter.value as string];
        return month.value
            .sort()
            .reduce((arr2: any, m2: string) => {
                return [...arr2, chosenMonthes[m2]];
            }, [])
            .join(',');
    };
    const searchParams = reactive<TDTAManagementQueryParams>({});

    const fakeDataList = ref<TDTAManagementTableData[]>([]);
    fakeDataList.value = genFakeData(20);
    const tableData = ref<TDTAManagementTableData[]>([]);
    const getTableData = () => {
        searchParams.current = paginationObj.current - 1;
        searchParams.size = paginationObj.size;
        searchParams.taName = name.value || '';
        searchParams.programCode = programCode.value || '';
        searchParams.year = year.value || '';
        searchParams.quarter = quarter.value || '';
        searchParams.month = getMonthStr();
        getTDTAManagementData(searchParams).then((res) => {
            tableData.value = res.data;
            paginationObj.total = parseInt(res.totalNum);
        });
    };

    onMounted(() => {
        getTableData();
    });

    return {
        fakeDataList,
        name,
        programCode,
        year,
        yearList,
        quarter,
        quarterList,
        month,
        monthList,
        searchParams,
        columns,
        getTableData,
        tableData,
        paginationObj,
        pageChange,
        sizeChange,
    };
};

export default useManagement;
